import { Cover } from './baseCover'
import browserCheck from '@ta-interaktiv/browsercheck'
import { srcSetConfiguration } from 'components/srcsets'
import React, { ComponentPropsWithoutRef } from 'react'
import s from './videoCover.module.scss'

interface VideoCoverProps extends ComponentPropsWithoutRef<typeof Cover> {
  video: {
    /** Videos for Webkit/Safari/iOS devices */
    mp4: {
      /** MP4 video in desktop resolution */
      desktop: string
      /** MP4 video in mobile resolution */
      mobile: string
    }
    /** Videos for Chrome/Android devices */
    webm: {
      /** WebM in desktop resolution */
      desktop: string
      /** WebM in mobile resolution */
      mobile: string
    }
  }
}

/**
 * A cover component that uses a video as it's background. It also needs a background image as the poster image and as a fallback, in case the video can't run.
 */
export function VideoCover(props: VideoCoverProps) {
  const supportsObjectFit =
    window.CSS &&
    window.CSS.supports &&
    window.CSS.supports('object-fit', 'cover')
  const isMobile = browserCheck.isMobile || browserCheck.isTablet
  const isIOS9 = /iPhone OS [6789]_/.test(navigator.userAgent)

  return (
    <Cover {...props}>
      {!isIOS9 && !browserCheck.isAndroidNativeApp && (
        <video
          autoPlay
          muted
          playsInline
          preload
          loop
          className={s.posterImage}
          poster={props.poster.sources['1200w']}
          ref={video => {
            if (video) {
              video.setAttribute('playsinline', '')
            }
          }}
        >
          {!isMobile && (
            <source src={props.video.webm.desktop} type='video/webm' />
          )}

          {isMobile && (
            <source src={props.video.webm.mobile} type='video/webm' />
          )}

          {!isMobile && (
            <source src={props.video.mp4.desktop} type='video/mp4' />
          )}

          {isMobile && <source src={props.video.mp4.mobile} type='video/mp4' />}

          {supportsObjectFit && (
            <img
              style={{
                height: props.coverHeight
              }}
              className={s.posterImage}
              src={props.poster.sources['600w']}
              srcSet={props.poster.srcSet}
              sizes={srcSetConfiguration.fullWidthSizeHint}
              alt=''
            />
          )}

          {!supportsObjectFit && (
            <div
              className={s.posterFallback}
              style={{
                height: props.coverHeight,
                backgroundImage: `url(${props.poster.sources['1200w']})`
              }}
            />
          )}
        </video>
      )}
    </Cover>
  )
}
