import React, { useMemo, useState } from 'react'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/transition.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/button.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/divider.css'
import { ShareButtons, DisplayTypes } from '@ta-interaktiv/react-share-buttons'
import s from './projectDescription.module.scss'
import Hashids from 'hashids'
import config from 'config'

interface Props {
  aimedAt?: string
  applicationConditions?: string
  applicationPossible: boolean
  dateAndPlace?: string
  description: string
  email: string
  logoName: string
  lead: string
  timeDemand?: string
  title: string
}

/**
 * A project description block.
 */
export function ProjectDescription({
  aimedAt,
  applicationPossible,
  applicationConditions,
  email,
  dateAndPlace,
  description,
  logoName,
  lead,
  timeDemand,
  title
}: Props) {
  const id = useMemo(
    () => new Hashids(title, 4, 'bcdfghklmnpqrstvwxz').encode(42),
    [title]
  )
  const [showDescription, setDescriptionState] = useState<boolean>(false)
  const toggleVisibility = () => setDescriptionState(prevState => !prevState)

  let logo = require('images/logos/missing.svg')

  try {
    logo = require('images/logos/' + logoName + '.svg')
  } catch (e) {
    if (e.code === 'MODULE_NOT_FOUND') {
      console.warn(`Logo with name '${logoName}' not found.`)
    } else {
      throw e
    }
  }

  return (
    <article className='project-description ui vertical segment' id={id}>
      <header
        className=''
        style={{ cursor: 'pointer', paddingBottom: '1.5em' }}
        onClick={toggleVisibility}
      >
        <h3 className={`ui image dividing blue header ${s.imageHeader}`}>
          <img className={`ui image ${s.image}`} src={logo} alt='' />

          <div className='content'>
            {title}
            <div className={`sub header ${s.lead}`}>{lead}</div>
          </div>
        </h3>
      </header>

      <main
        className={showDescription ? s.descriptionVisible : s.descriptionHidden}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />

        <table className='ui very basic definition table'>
          <tbody>
            {aimedAt && (
              <tr className='top aligned'>
                <td>Angebot richtet sich an</td>
                <td dangerouslySetInnerHTML={{ __html: aimedAt }} />
              </tr>
            )}
            {timeDemand && (
              <tr className='top aligned'>
                <td>Zeitaufwand</td>
                <td dangerouslySetInnerHTML={{ __html: timeDemand }} />
              </tr>
            )}
            {dateAndPlace && (
              <tr className='top aligned'>
                <td>Datum&#8202;/&#8202;Ort</td>
                <td dangerouslySetInnerHTML={{ __html: dateAndPlace }} />
              </tr>
            )}
            {applicationConditions && (
              <tr className='top aligned'>
                <td>Bewerbung</td>
                <td
                  dangerouslySetInnerHTML={{ __html: applicationConditions }}
                />
              </tr>
            )}
          </tbody>
        </table>

        <aside className='ui center aligned fluid container'>
          <ShareButtons
            communities={config.communities}
            url={`http://www.younews.ch#${id}`}
            displayType={DisplayTypes.HORIZONTAL_ICONS}
          />
        </aside>
      </main>

      <footer className='ui center aligned very fitted vertical segment'>
        <div className='ui buttons'>
          <button
            className='ui secondary basic icon button'
            onClick={toggleVisibility}
          >
            <i
              className={`${
                showDescription ? 'close' : 'angle double down'
              } icon`}
            />
            <span className='mobile hidden'>
              {showDescription ? 'Schliessen' : 'Weitere Informationen'}
            </span>
            <span className='mobile only'>{showDescription ? '' : 'Mehr'}</span>
          </button>
          <a
            href={`mailto:${email}?subject=Anmeldung Jugendmedienwoche YouNews 2024`}
            className={`ui blue ${
              !applicationPossible ? 'disabled' : ''
            } button`}
          >
            <i className='edit icon' />
            {applicationPossible ? 'Anmelden' : 'Anmeldung nicht mehr möglich'}
          </a>
        </div>
      </footer>
    </article>
  )
}
