/**
 * @fileOverview The main entry point to your standalone. Start editing here.
 */

import React from 'react'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/reset.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/site.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/segment.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/container.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/header.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/image.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/list.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/table.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/icon.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/grid.css'
import 'styles/App.scss'
import { TypescriptComponent } from 'components/typescriptExample'
import { ShareButtons, DisplayTypes } from '@ta-interaktiv/react-share-buttons'
import config from 'config'
import { VideoCover } from 'components/cover/videoCover'
import { IconList } from 'components/iconList'
import IntroText from 'text/intro.mdx'
import { MDXProvider } from '@mdx-js/react'

import { ProjectResults } from 'components/projectResults'
import { PreviousProjects } from 'components/previousProjects'
import { ProjectDescriptions } from 'components/projectDescriptions'

const posterObject = require('images/cover/poster.jpg?sizes=300w+600w+900w+1200w+1800w+2400w&placeholder')
const coverVideo = {
  webm: {
    desktop: require('images/cover/cover-desktop.webm'),
    mobile: require('images/cover/cover-mobile.webm')
  },
  mp4: {
    desktop: require('images/cover/cover-desktop.mp4'),
    mobile: require('images/cover/cover-mobile.mp4')
  }
}

const components = {
  a: props => (
    <a className='nobreak' {...props}>
      <i className='ui small external link icon' />
      {props.children}
    </a>
  )
}

export function AppComponent() {
  return (
    <div className='App'>
      <VideoCover
        poster={posterObject}
        video={coverVideo}
        coverHeight='75vh'
        showTextShadow
        position='bottom'
        showTopGradient={false}
        padding='15%'
        title={
          <img
            alt='Jugendliche machen Medien'
            src={require('images/cover/Logo_YouNews_Claim.png')}
            className='ui image centered'
            style={{ width: 'auto', maxHeight: '35vh' }}
          />
        }
        subtitle={
          <div>
            7. Schweizer Jugendmedienwoche <br />
            6. bis 12.&nbsp;Mai&nbsp;2024
          </div>
        }
      />

      <IconList />

      <div className='ui vertical segment'>
        <div className='ui text container' style={{ fontSize: '1.1rem' }}>
          <MDXProvider components={components}>
            <IntroText />
          </MDXProvider>
        </div>

        <div className='ui center aligned container'>
          <ShareButtons
            communities={config.communities}
            className='huge blue'
            url='http://www.younews.ch'
            displayType={DisplayTypes.HORIZONTAL_ICONS}
          />
        </div>
      </div>
      <ProjectDescriptions />

      <div className='ui grey inverted secondary vertical small relaxed segment'>
        <div className='ui container'>
          <div className='ui three column stackable inverted horizontally divided grid'>
            <div className='row'>
              <div className='column'>
                <h3 className='ui inverted header'>Über uns</h3>
                <p>
                  Das Projekt <cite>Schweizer Jugendmedienwoche</cite> ist
                  entstanden auf Initiative von <strong>Viviane Manz</strong>{' '}
                  (SRF-«Tagesschau»), <strong>Michael Marti</strong>{' '}
                  («Tages-Anzeiger») und <strong>Franz Fischlin</strong>{' '}
                  (SRF-«Tagesschau», «Medienclub», Verein Qualität im
                  Journalismus). Projektpartner ist das Medieninstitut des
                  Verlegerverbandes SCHWEIZER MEDIEN.
                  <img
                    className='ui image'
                    src={require('images/logos/medieninstitut.svg')}
                    alt=''
                    style={{ width: '70%', height: 'auto', marginTop: '2em' }}
                  />
                </p>
              </div>
              <div className='column'>
                <h3 className='ui inverted header'>Kontakt</h3>
                <p>
                  Bei Fragen zum Gesamtprojekt <cite>Jugendmedienwoche</cite>{' '}
                  wenden Sie sich an Michael Marti.
                </p>
                <p>
                  YouNews.ch
                  <br />
                  Jugendmedienwoche
                  <br />
                  c/o Tamedia <br />
                  Michael Marti
                  <br />
                  Werdstrasse 21 <br />
                  8021 Zürich <br />
                  <a href='mailto:michael.marti@tamedia.ch?subject=Jugendmedientage'>
                    <i className='mail icon' />
                    michael.marti@tamedia.ch
                  </a>
                </p>
              </div>
              <div className='column'>
                <h3 className='ui inverted header'>Weitererzählen</h3>
                <ShareButtons
                  className='basic inverted'
                  communities={config.communities}
                  url='http://www.younews.ch'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ui grey attached very fitted secondary tiny inverted segment'>
        Gestaltung Website: Interaktiv-Team, Tages-Anzeiger
      </div>
    </div>
  )
}
