import React from 'react'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/button.css'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/icon.css'

export function PreviousProjects() {
  return (
    <div className='ui vertical center aligned  segment'>
      <a
        className='ui large basic right labeled icon secondary button'
        href='https://interaktiv.tagesanzeiger.ch/2020/younews/'
      >
        <i className='right chevron icon' />
        Projekte vergangener Jahre ansehen
      </a>
    </div>
  )
}
