import React, { FC, ReactNode } from 'react'
import s from './baseCover.module.scss'
import { SrcsetImage } from 'components/cover/types'

interface BaseCoverProps {
  title: ReactNode
  subtitle: ReactNode
  /** How high the cover should be */
  coverHeight?: string
  /** Show a gradient on top, used to create enough contrast for the masthead */
  showTopGradient?: boolean
  /** Give the title and subtitle a text shadow, so there's enough contrast */
  showTextShadow?: boolean
  /** The poster image that should be shown as a background. */
  poster: SrcsetImage
  /** Should the title box be placed relative to the top or the bottom? */
  position?: 'top' | 'bottom'
  /** same as position, but for the mobile version. If not given will take the value from `position` */
  mobilePosition?: 'top' | 'bottom'
  /** The distance from the top or bottom, as defined in position. */
  padding?: string
  /** In case the mobile design needs a different design, a differing distance can be set here, otherwise the value from `padding` is taken. */
  mobilePadding?: string
}

export const Cover: FC<BaseCoverProps> = ({
  coverHeight = '66vh',
  title,
  subtitle,
  showTopGradient = true,
  showTextShadow = true,
  poster,
  position = 'top',
  mobilePosition = position,
  padding = '3ex',
  mobilePadding = padding,
  children
}) => {
  const placeholderColor = poster.placeholder.color
  const placeholderColorString = `rgba(${placeholderColor.join(',')})`
  const transparentPlaceholderColorString = `rgba(${placeholderColor[0]}, ${placeholderColor[1]}, ${placeholderColor[2]}, 0)`
  const textShadow = showTextShadow
    ? `0 0 2em ${placeholderColorString}, 0 0 0.75em ${placeholderColorString}`
    : 'none'

  return (
    <div
      className={s.cover}
      style={{
        backgroundColor: placeholderColorString,
        backgroundImage: `url(${poster.placeholder.url})`,
        height: coverHeight
      }}
    >
      {children}
      {showTopGradient && (
        <div
          className={s.topGradient}
          style={{
            background: `linear-gradient(${placeholderColorString}, ${transparentPlaceholderColorString})`
          }}
        />
      )}

      <div
        className={`ui mobile only ${s.titleBox}`}
        style={{
          [mobilePosition]: mobilePadding,
          textShadow: textShadow
        }}
      >
        <Header title={title} subtitle={subtitle} />
      </div>

      <div
        className={`ui mobile hidden ${s.titleBox}`}
        style={{
          [position]: padding,
          textShadow: textShadow
        }}
      >
        <div className='ui container'>
          <Header title={title} subtitle={subtitle} />
        </div>
      </div>
    </div>
  )
}

function Header({
  title,
  subtitle
}: {
  title: ReactNode
  subtitle: ReactNode
}) {
  return (
    <h1 className='ui inverted huge centered header'>
      {title}
      <div className='sub header'>{subtitle}</div>
    </h1>
  )
}
