import React from 'react'
import styles from './iconList.module.scss'

const companyList = [
  'srf-tagesschau',
  'tagesanzeiger',
  'sonntagszeitung',
  'nzz',
  // 'nzz-am-sonntag',
  // 'watson',
  'blick',
  'srf-arena',
  'srf-club',
  'srf-einstein',
  'srf-kassensturz',
  'srf-schweizaktuell',
  'virus',
  'srf-3',
  'radio-argovia',
  'radio-24',
  'radio-fm1',
  'radio-pilatus',
  'radio-32',
  'virgin',
  'rsn',
  'maz',
  'bieler-tagblatt',
  'berner_oberlaender',
  'thuner_tagblatt',
  'engadiner-post',
  // 'frutiglaender',
  // 'shn',
  // 'suedostschweiz',
  'srf-10vor10',
  // 'pctipp',
  'r4tng',
  // 'tv4tng',
  'videogang',
  'volksstimme',
  'zueritipp',
  'vsm',
  'zhaw',
  '20-minuten',
  'vaterland'
]
export function IconList() {
  return (
    <div className='ui vertical very fitted secondary segment'>
      <div className='ui container'>
        <div className={styles.iconListContainer}>
          {companyList.map(companyId => {
            return (
              <img
                key={companyId}
                src={require('images/logos/' + companyId + '.svg')}
                alt=''
                className={styles.icon}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
