
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Header } from 'components/header'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header title='Jugend-Event YouNews geht in die siebte Runde!' lead='Mitmachen statt zuschauen: Die Schweizer Jugendmedienwoche YouNews findet 2024 bereits zum siebten Mal statt – neu nicht mehr im Winter, sondern im Frühling. ' mdxType="Header" />
    <p>{`In der Woche vom 6. bis zum 12. Mai 2024 können Jugendliche erneut hinter die Kulissen der wichtigsten Schweizer Medienhäuser blicken und gemeinsam mit Profis Artikel verfassen, Videos produzieren, Radio-Shows moderieren oder auf Social-Media-Plattformen Stories posten. Das Ziel der 2018 lancierten Schweizer Medienveranstaltung für Jugendliche lautet: Journalismus verstehen, Journalismus erleben, Journalismus mitgestalten. Die in der Medienwoche erstellten Artikel, Radio- und Videobeiträge werden im jeweiligen Gastmedium veröffentlicht. Schulklassen oder Gruppen von Jugendlichen zwischen 13 und 20 Jahren sowie ihre Lehrpersonen können sich bis Mitte März 2024 unter dem jeweiligen Projektbeschrieb für eine Teilnahme bewerben.`}</p>
    <p>{`Für weitere Einblicke, folgt unserem Insta-Channel
`}<a parentName="p" {...{
        "href": "https://www.instagram.com/explore/tags/younews2024/"
      }}>{`#younews2024`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;