import React, { useEffect, useState } from 'react'
import { Project } from './Project'
import snarkdown from 'snarkdown'
import { sanitize } from 'dompurify'

import { ProjectDescription } from 'components/projectDescription'
import { PreviousProjects } from 'components/previousProjects'

export function ProjectDescriptions() {
  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
    fetch(
      'https://storage.googleapis.com/ta-interaktiv/younews/Ausschreibungen.json'
    )
      .then(response => response.json())
      .then(data => setProjects(Object.values(data)))
  }, [])

  return (
    <div className='ui vertical white segment'>
      <div className='ui text container'>
        <h2 className='ui center aligned header'>Projekte</h2>

        {projects.map((project: Project) => {
          return (
            <ProjectDescription
              key={project.Titel}
              applicationPossible={project.BewerbungMoeglich === 'TRUE'}
              description={sanitize(snarkdown(project.Beschreibung))}
              email={project.eMail}
              logoName={project.Logoname}
              lead={sanitize(snarkdown(project.Lead))}
              title={project.Titel}
              aimedAt={sanitize(snarkdown(project.Zielgruppe))}
              timeDemand={sanitize(snarkdown(project.Zeitaufwand))}
              dateAndPlace={sanitize(snarkdown(project.DatumOrt))}
              applicationConditions={sanitize(snarkdown(project.Bewerbung))}
            />
          )
        })}

        <PreviousProjects />
      </div>
    </div>
  )
}
