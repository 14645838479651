import React, { ReactNode } from 'react'
import '@ta-interaktiv/semantic-ui/semantic/dist/components/header.css'

interface Props {
  title: ReactNode
  lead: ReactNode
}

export function Header({ title, lead }: Props) {
  return (
    <h2 className='ui blue header'>
      {title}
      <div className='sub header' style={{ fontSize: '1.5rem' }}>
        {lead}
      </div>
    </h2>
  )
}
