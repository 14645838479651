export const srcSetConfiguration = {
  /**
   * Default srcSet sizes.
   * @type {string[]}
   */
  srcSetSizes: ['300w', '600w', '900w', '1200w', '1800w', '2400w'],

  /**
   * Get the full size string – the widths joined with '+'.
   * @returns {string}
   */
  get sizeString() {
    return this.srcSetSizes.join('+')
  },

  fullWidthSizeHint: '100vw',

  textContainerSizeHintCategories: [
    '(min-width: 600px) 581px',
    'calc(100vw - 2 * 13px)'
  ],

  get textContainerSizeHint() {
    return this.textContainerSizeHintCategories.join(', ')
  },

  containerSizeHintCategories: [
    '(min-width: 1200px) 1115px',
    '(min-width: 900px) calc(100vw - 2 * 1.5rem - 17px)',
    '(min-width: 600px) calc(100vw - 2 * 1rem - 17px)',
    'calc(100vw - 2 * 13px)'
  ],

  get containerSizeHint() {
    return this.containerSizeHintCategories.join(', ')
  }
}
